<template>
  <div class="login">
    <h2 class="margin-bottom-2">¡Bienvenido Socio!</h2>
    <div>
      <img src="../assets/logo_vertical.png" alt="Logo">
    </div>
    <template v-if="validate">
      <h2>Este certificado es válido,</h2>
      <h2>inicia sesión para ver mas información</h2>
    </template>
    <div class="form">
      <div class="form__group">
        <label>Correo electronico</label>
        <input type="text" v-model="email">
      </div>
      <div class="form__group">
        <label>Contraseña</label>
        <input type="password" autocomplete="new-password" v-model="password">
      </div>
      <button type="button" @click="login" :disabled="loading">
        {{ loading ? 'Cargando...' : 'Continuar' }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import useAuthStore from '../stores/authStore';

export default {
  setup() {
    const authStore = useAuthStore();
    const email = ref('mail2@mail.com');
    const password = ref('password');
    const loading = ref(false);
    const route = useRoute();
    const validate = ref(null);

    if (route.query.token) {
      validate.value = route.query.token;
    }

    const login = async () => {
      loading.value = true;
      await authStore.login(email.value, password.value);
      loading.value = false;
    };

    return {
      email,
      password,
      login,
      loading,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/views/login.scss";
</style>
